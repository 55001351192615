import type { AppRole } from "@asap/shared";

export function isTeamMember(roles: AppRole[]) {
  return roles.includes("team_member");
}

export function alreadyOnboarded(teamMember: TeamMember | undefined, roles: AppRole[]) {
  return !!teamMember && !hasOnlyTeamMemberRole(roles);
}
export function hasOnlyTeamMemberRole(roles: AppRole[]) {
  return roles.length === 1 && roles.includes("team_member");
}
